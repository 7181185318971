import { FC, useEffect } from 'react';
import { logEvent } from 'firebase/analytics';

import '../../App.scss';
import translations from '../../assets/translations';
import PaymentResultFailure from '../../components/payment-result/PaymentResultFailure';
import { analytics } from '../../services/FirebaseService';

interface Props {
  history: any;
  location: any;
}

const FailureScreen: FC<Props> = ({ history, location }) => {
  const error: string = location.state?.error;
  const userId = location.state.userId;
  const organisation = location.state.organisation;
  const transaction = location.state.transaction;

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - payment error',
      firebase_screen_class: 'Pay - payment error',
    });
  }, []);

  return (
    <PaymentResultFailure
      title={translations['error.title']}
      text={error || translations['error.text']}
      cancel={() => history.push('/helpImprove')}
      onClick={() =>
        history.push('/pay/method', {
          userId,
          organisation,
          transaction,
        })
      }
    />
  );
};

export default FailureScreen;
