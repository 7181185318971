import { FC, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
var aesjs = require('aes-js');
import { logEvent } from 'firebase/analytics';

import '../../App.scss';
import AmountView from '../../components/amount/AmountView';
import Context, { ActionTypes } from '../../components/Context';
import { analytics } from '../../services/FirebaseService';
import { getOrganizationInfo } from '../../services/FirestoreService';

interface Props {
  match: any;
}

const useQuery = () => new URLSearchParams(useLocation().search);

var key = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

const EnterPaymentAmountScreen: FC<Props> = () => {
  const { dispatch } = useContext(Context);
  let query = useQuery();
  var encryptedBytes = aesjs.utils.hex.toBytes(query.get('s'));
  var aesCtr = new aesjs.ModeOfOperation.ctr(key);
  var decryptedBytes = aesCtr.decrypt(encryptedBytes);
  var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
  const { id, organisationId, amount, ref } = JSON.parse(decryptedText);

  useEffect(() => {
    if (amount) {
      logEvent(analytics, 'screen_view', {
        firebase_screen: 'Pay - prefilled',
        firebase_screen_class: 'Pay - prefilled',
      });
    } else {
      logEvent(analytics, 'screen_view', {
        firebase_screen: 'Pay',
        firebase_screen_class: 'Pay',
      });
    }

    displayUserDetails();
    dispatch({ type: ActionTypes.setState, state: { userId: id } });
  }, []);

  const [loading, setLoading] = useState(false);

  const displayUserDetails = async () => {
    setLoading(true);
    try {
      const res = await getOrganizationInfo(organisationId);
      console.log(res);
      if (res.result) {
        dispatch({
          type: ActionTypes.setState,
          state: { organisation: res.data },
        });
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return <AmountView amount={amount} refNum={ref} loading={loading} />;
};

export default EnterPaymentAmountScreen;
