import { memo, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

import Context from '../../components/Context';
import PaymentMethodContent from '../../components/payment-method/PaymentMethodContent';
import { analytics } from '../../services/FirebaseService';
import { useStripe } from '@stripe/react-stripe-js';
import StripeApi from '../../api/StripeApi';
import { stringToCents } from '../../utils/Money';
import EnvVars from '../../services/EnvVars';

type Props = { location: any };

const useQuery = () => new URLSearchParams(useLocation().search);

const PaymentMethodScreen = memo(({ location }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const { paymentRequest } = useContext(Context);
  let query = useQuery();

  const paymentFields = location.state?.userId
    ? location.state
    : JSON.parse(sessionStorage.getItem('paymentFields') || '{}');
  const { userId, organisation, transaction } = paymentFields;
  console.log(location);

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - select payment',
      firebase_screen_class: 'Pay - select payment',
    });

    const id = query.get('payment_intent');
    const client_secret = query.get('payment_intent');
    const redirect_status = query.get('redirect_status');
    if (id && client_secret && redirect_status !== 'failed') {
      history.push('/pay/success', {
        organisation,
        transaction: {
          ...transaction,
          id,
          clientSecret: client_secret,
        },
      });
    } else if (redirect_status === 'failed') {
      // console.log(window.location.href);
      // history.push('/pay/failure', { error: 'Something went wrong' });
    }
  }, []);

  return (
    <PaymentMethodContent
      isLoading={isLoading}
      paymentFields={paymentFields}
      bankTransferClick={async () => {
        setLoading(true);
        const amount = stringToCents(transaction.amount);
        try {
          const {
            data: { id, clientSecret },
          } = await StripeApi.createPaymentIntent({
            userId,
            organisationId: organisation?.id || '',
            amount,
            currency: 'gbp',
            date: transaction.date,
            refNum: transaction.refNum,
            isOpenBanking: true,
          });

          const stripe = Stripe(EnvVars.STRIPE_PK, {
            betas: ['pay_by_bank_beta_1'],
          });
          sessionStorage.setItem(
            'paymentFields',
            JSON.stringify({
              userId,
              organisation,
              transaction,
            }),
          );
          // @ts-expect-error
          const { paymentIntent, error } = await stripe.confirmPayByBankPayment(
            clientSecret,
            {
              return_url: window.location.href,
            },
          );
          console.log(paymentIntent, error);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }}
      cardClick={() =>
        history.push('/pay/confirm', {
          userId,
          organisation,
          transaction,
        })
      }
      paymentRequestParam={paymentRequest}
    />
  );
});

export default PaymentMethodScreen;
