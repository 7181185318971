import { FC, memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import './AmountView.scss';
import translations from '../../assets/translations';
import DataField from '../common/DataField';
import Container from '../../widgets/layout/Container';
import Body from '../../widgets/layout/Body';
import PrimaryButton from '../common/Button';
import Context, { ActionTypes } from '../Context';
import UserAvatar from '../common/UserAvatar';

type Props = {
  amount: string;
  refNum?: string;
  loading?: boolean;
};

const Schema = Yup.object().shape({
  amount: Yup.number()
    .min(1, 'Minimum payment is £1.')
    .required()
    .label('Amount'),
  refNum: Yup.string(),
});

const AmountView: FC<Props> = memo(({ amount, refNum, loading }: Props) => {
  const { userId, organisation, dispatch } = useContext(Context);
  const history = useHistory();
  const isInput = !amount;

  return (
    <Container>
      <Body>
        <UserAvatar image={organisation?.profilePhoto} />
        <DataField title={translations.payTo} value={organisation?.orgname} />
        <Formik
          validationSchema={Schema}
          initialValues={{
            amount: amount?.toString() || '',
            refNum: refNum || '',
          }}
          onSubmit={({ amount, refNum }) => {
            try {
              const date = moment().unix();
              const transaction = {
                amount,
                refNum,
                date,
              };
              dispatch({ type: ActionTypes.setState, state: { transaction } });
              history.push('/pay/providers', {
                userId,
                organisation,
                transaction,
              });
            } catch (error: any) {
              alert(error.toString());
            }
          }}>
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form className="flex-form" onSubmit={handleSubmit}>
              <div>
                <DataField
                  name="amount"
                  isAmount
                  isInput={isInput}
                  title={
                    isInput ? translations.enterAmount : translations.amount
                  }
                  value={values.amount}
                  error={errors.amount}
                  handleChange={(value: string) =>
                    setFieldValue('amount', value)
                  }
                  handleBlur={handleBlur}
                />
                {(isInput || !!values.refNum) && (
                  <DataField
                    name="refNum"
                    isInput={isInput}
                    isOptional={isInput}
                    title={isInput ? translations.ref : translations.refInv}
                    value={values.refNum}
                    error={errors.refNum}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                )}
              </div>
              <PrimaryButton
                isLoading={isSubmitting || loading}
                type="submit"
                label={translations.payNow}
              />
            </Form>
          )}
        </Formik>
      </Body>
    </Container>
  );
});

export default AmountView;
