import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import '@fontsource/roboto';
import { fetchAndActivate } from 'firebase/remote-config';

import './App.scss';
import DesktopScreen from './screens/desktop/DesktopScreen';
import EnterPaymentDetailsScreen from './screens/payment/EnterPaymentDetailsScreen';
import SuccessfulPaymentScreen from './screens/payment/SuccessfulPaymentScreen';
import EnterPaymentAmountScreen from './screens/payment/EnterPaymentAmountScreen';
import PaymentMethodScreen from './screens/payment/PaymentMethodScreen';
import ProvidersScreen from './screens/payment/ProvidersScreen';
import ReceiptScreen from './screens/payment/ReceiptScreen';
import FailureScreen from './screens/payment/FailureScreen';
import HelpImprove from './screens/payment/HelpImprove';
import ReceiptConfirmScreen from './screens/payment/ReceiptConfirmScreen';
import { stripePromise } from './services/stripe/StripeService';
import OpenPlaidLink from './screens/redirects/OpenPlaidLink';
import DownloadBiz from './screens/redirects/DownloadBiz';
import { remoteConfig } from './services/FirebaseService';
import MarkdownInfoScreen from './screens/MarkdownInfoScreen';

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {})
      .catch((err) => {})
      .finally(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return <div />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap',
          },
        ],
      }}>
      <Router>
        <Switch>
          <Route exact path="/pay" component={EnterPaymentAmountScreen} />
          <Route exact path="/pay/providers" component={ProvidersScreen} />

          <Route exact path="/pay/method" component={PaymentMethodScreen} />
          <Route exact path="/pay/more-info" component={MarkdownInfoScreen} />

          <Route
            exact
            path="/pay/confirm"
            component={EnterPaymentDetailsScreen}
          />
          <Route
            exact
            path="/pay/success"
            component={SuccessfulPaymentScreen}
          />
          <Route exact path="/pay/failure" component={FailureScreen} />
          <Route exact path="/pay/receipt" component={ReceiptScreen} />
          <Route
            exact
            path="/receiptConfirm"
            component={ReceiptConfirmScreen}
          />
          <Route exact path="/helpImprove" component={HelpImprove} />
          <Route exact path="/pay/desktop" component={DesktopScreen} />

          <Route exact path="/oauth/plaid" component={OpenPlaidLink} />

          <Route exact path="/download/biz" component={DownloadBiz} />
        </Switch>
      </Router>
    </Elements>
  );
};

export default App;
