import { useCallback, useEffect, FunctionComponent } from 'react';
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from 'react-plaid-link';
import { useHistory } from 'react-router';

import PlaidApi from '../../api/PlaidApi';
import EnvVars from '../../services/EnvVars';

interface Props {}

const OpenPlaidLink: FunctionComponent<Props> = ({}) => {
  const history = useHistory();
  const { token, organisation, transaction, userId, counter } = JSON.parse(
    localStorage.getItem('plaidConfig') || '{}',
  );
  console.log({ token, organisation, transaction });
  console.log(localStorage.getItem('plaidConfig'));

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token, metadata) => {
      try {
        await PlaidApi.preinitTransaction(
          organisation.id,
          userId,
          transaction.refNum,
          transaction.amount,
          transaction.id,
          counter,
        );
        history.push('/pay/success', { organisation, transaction });
      } catch (error) {
        history.push('/pay/failure', {
          userId,
          organisation,
          transaction,
          error,
        });
      }
    },
    [],
  );

  const config: PlaidLinkOptions = {
    env: EnvVars.PLAID_ENV,
    token,
    receivedRedirectUri: document.location.href,
    onSuccess,
    onEvent: (name, metadata) => {
      if (name === 'ERROR') {
        history.push('/pay/failure', { userId, organisation, transaction });
      }
    },
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (!ready) {
      return;
    }
    open();
  }, [ready, open]);

  return null;
};

export default OpenPlaidLink;
