import { FC, memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useStripe,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import {
  PaymentIntent,
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js';

import './ApplePayment.scss';
import { StripeService } from '../../services/stripe/StripeService';
import { stringToCents } from '../../utils/Money';
import StripeApi from '../../api/StripeApi';

type Props = {
  disabled?: boolean;
  paymentFields: any;
  paymentRequestParam: any;
};

const ApplePayment: FC<Props> = memo(
  ({ disabled, paymentFields, paymentRequestParam }) => {
    const history = useHistory();
    const stripe = useStripe();
    if (!stripe) {
      return <div></div>;
    }
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
    const { userId, organisation, transaction } = paymentFields;

    const amount = stringToCents(transaction.amount);

    const makePayment = async (ev: PaymentRequestPaymentMethodEvent) => {
      try {
        const paymentIntentRes = (
          await StripeApi.proccessPayment({
            userId,
            organisationId: organisation?.id || '',
            amount,
            currency: 'gbp',
            date: transaction.date,
            refNum: transaction.refNum,
            paymentMethodId: ev.paymentMethod.id,
            country: ev.paymentMethod.card?.country || 'undefined',
          })
        ).data;

        const onSucesss = () => {
          history.push('/pay/success', {
            organisation,
            transaction: {
              ...transaction,
              id: paymentIntentRes?.id,
              clientSecret: paymentIntentRes?.client_secret,
            },
          });
        };

        if (
          paymentIntentRes.status === 'requires_action' &&
          paymentIntentRes.client_secret
        ) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            paymentIntentRes.client_secret,
          );
          if (error) {
            throw new Error(error.message);
          }
          if (paymentIntent?.status === 'succeeded') {
            onSucesss();
            ev.complete('success');
          } else {
            throw new Error('Something went wrong');
          }
        } else if (paymentIntentRes.status === 'succeeded') {
          onSucesss();
          ev.complete('success');
        } else {
          throw new Error('Something went wrong');
        }
      } catch (error) {
        ev.complete('fail');
        history.push('/pay/failure', { error });
      }
    };

    useEffect(() => {
      if (stripe) {
        if (paymentRequestParam) {
          console.log(paymentRequestParam);
          paymentRequestParam.on('paymentmethod', makePayment);
          setPaymentRequest(paymentRequestParam);
        } else {
          const pr = stripe.paymentRequest({
            country: 'GB',
            currency: 'gbp',
            total: { label: 'nabyPay', amount },
            requestPayerName: true,
            requestPayerEmail: true,
          });
          // Check the availability of the Payment Request API first.
          pr.canMakePayment().then((result) => {
            if (result) {
              pr.on('paymentmethod', makePayment);
              setPaymentRequest(pr);
            }
          });
        }
      }
    }, []);

    if (paymentRequest) {
      return (
        <div
          className="PayButton"
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: { paymentRequestButton: { height: '60px' } },
            }}
          />
        </div>
      );
    }

    return <div></div>;
  },
);

export default ApplePayment;
