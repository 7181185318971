import { FC, CSSProperties, ReactNode } from 'react';
import '../../App.scss';

type Props = { style?: CSSProperties; children: ReactNode };

const Body: FC<Props> = ({ style, children }) => {
  return (
    <div style={style} className="flex-body">
      {children}
    </div>
  );
};

export default Body;
