import { getNumber, getString } from '@firebase/remote-config';

import translations from '../assets/translations';
import { remoteConfig } from '../services/FirebaseService';

export const rcNum = (key: string) => getNumber(remoteConfig, key);
export const rcString = (key: string) => getString(remoteConfig, key);
export const getImageUrl = (imageName: string) =>
  translations
    .formatString(getString(remoteConfig, 'image_url'), imageName)
    ?.toString();
