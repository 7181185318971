import { logEvent } from 'firebase/analytics';
import ReactMarkdown from 'react-markdown';

import { analytics } from '../services/FirebaseService';
import { rcString } from '../utils/RemoteConfig';
import Container from '../widgets/layout/Container';
import Body from '../widgets/layout/Body';
import Header from '../components/common/Header';

const MarkdownInfoScreen = () => {
  logEvent(analytics, 'screen_view', {
    firebase_screen: 'More info',
    firebase_screen_class: 'More info',
  });

  return (
    <Container>
      <Body style={{ overflow: 'hidden' }}>
        <Header />
        <div style={{ overflowY: 'scroll' }}>
          <ReactMarkdown>{JSON.parse(rcString('web_info_ob'))}</ReactMarkdown>
        </div>
      </Body>
    </Container>
  );
};

export default MarkdownInfoScreen;
