import { FC, memo } from 'react';
import { isDesktop } from 'react-device-detect';

import Body from '../../widgets/layout/Body';
import Bottom from '../../widgets/layout/Bottom';
import Container from '../../widgets/layout/Container';
import Button from '../common/Button';
import translations from '../../assets/translations';
import { ReactComponent as UnhappyFace } from '../../assets/images/ic-unhappy.svg';

type Props = {
  title: string | string[];
  text: string;
  btnText?: string;
  cancel?: () => void;
  onClick: () => void;
};

const IMAGE_SIZE = 150;

const PaymentResultFailure: FC<Props> = memo(
  ({ title, text, btnText, cancel, onClick }) => (
    <Container>
      <Body>
        <UnhappyFace
          style={{
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
            alignSelf: 'center',
          }}
        />
        <p className="failure-title">{title}</p>
        <p
          className="failure-text"
          style={isDesktop ? { overflowY: 'auto', maxHeight: '200px' } : {}}>
          {text}
        </p>
      </Body>
      <Bottom style={{ flexDirection: 'column' }}>
        {!!cancel && (
          <Button
            btnType="outline"
            label={translations.cancelPayment}
            onClick={cancel}
          />
        )}
        <Button label={btnText || translations.tryAgain} onClick={onClick} />
      </Bottom>
    </Container>
  ),
);

export default PaymentResultFailure;
