import { FC, memo } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import translations from '../../assets/translations';
import Body from '../../widgets/layout/Body';
import Container from '../../widgets/layout/Container';
import Button from '../common/Button';
import DataField from '../common/DataField';
import Header from '../common/Header';

type Props = { onPress: (email: string) => void };

const Schema = Yup.object().shape({
  email: Yup.string().email().required().label('Email'),
});

const ReceiptContent: FC<Props> = memo(({ onPress }) => (
  <Container>
    <Body>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Schema}
        onSubmit={({ email }) => {
          onPress(email);
        }}>
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <Form className="flex-form" onSubmit={handleSubmit}>
            <div>
              <Header>{translations['receipt.header']}</Header>
              <DataField
                isInput
                name="email"
                type="email"
                title={translations.email}
                value={values.email}
                error={errors.email}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <Button type="submit" label={translations.sendNow} />
          </Form>
        )}
      </Formik>
    </Body>
  </Container>
));

export default ReceiptContent;
