/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import './CardSectionStyles.scss';
import translations from '../../assets/translations';

const elementOptions = {
  style: {
    base: {
      fontFamily: 'Montserrat',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#7a7a7a',
      '::placeholder': { color: 'rgba(0,0,0,0.3)' },
    },
  },
};

const CardSection = React.memo(() => (
  <div className="card-section">
    <div className="card-number">
      <label>{translations.cardNum}</label>
      <CardNumberElement className="StripeInput" options={elementOptions} />
    </div>
    <div className="horizontal-container">
      <div className="element">
        <label>{translations.expiry}</label>
        <CardExpiryElement className="StripeInput" options={elementOptions} />
      </div>
      <div style={{ width: 20 }} />
      <div className="element">
        <label>{translations.CVV}</label>
        <CardCvcElement className="StripeInput" options={elementOptions} />
      </div>
    </div>
  </div>
));

export default CardSection;
