import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getRemoteConfig } from 'firebase/remote-config';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';

import CONFIG_DEV from './firebase-config/config-dev.json';
import CONFIG_STAGE from './firebase-config/config-stage.json';
import CONFIG_PROD from './firebase-config/config-prod.json';
import { currentEnv, GetEnvVars } from './EnvVars';

const getConfig = () => {
  //@ts-ignore
  if (currentEnv === 'STAGE') {
    return CONFIG_STAGE;
    //@ts-ignore
  } else if (currentEnv === 'PROD') {
    return CONFIG_PROD;
  }
  return CONFIG_DEV;
};

export const app = initializeApp(getConfig());
export const db = getFirestore(app);
export const remoteConfig = getRemoteConfig(app);
export const analytics = getAnalytics(app);

export const functions = getFunctions(app, 'europe-west2');
// connectFunctionsEmulator(functions, '192.168.31.148', 5000);

// @ts-ignore
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = '000ccee6-10d6-448c-95a7-7f65dd00e5de';
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(GetEnvVars(currentEnv).RECAPTCHA),
  isTokenAutoRefreshEnabled: true,
});
