import { FC, memo, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { logEvent } from 'firebase/analytics';

import ProvidersContent from '../../components/providers/ProvidersContent';
import { stringToCents } from '../../utils/Money';
import Context, { ActionTypes } from '../../components/Context';
import { analytics } from '../../services/FirebaseService';

type Props = { location: any };

const ProvidersScreen: FC<Props> = memo(({ location }: Props) => {
  const history = useHistory();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const { dispatch } = useContext(Context);

  const userId = location.state.userId;
  const organisation = location.state.organisation;
  const transaction = location.state.transaction;
  const amount = stringToCents(transaction.amount);

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - safe',
      firebase_screen_class: 'Pay - safe',
    });

    const createPaymentRequest = async () => {
      try {
        if (stripe) {
          const pr = stripe.paymentRequest({
            country: 'GB',
            currency: 'gbp',
            total: { label: 'nabyPay', amount },
            requestPayerName: true,
            requestPayerEmail: true,
          });

          const result = await pr.canMakePayment();
          if (result) {
            console.log(pr);
            setPaymentRequest(pr);
          }
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    createPaymentRequest();
  }, []);

  const onClick = async () => {
    const params = {
      userId,
      organisation,
      transaction,
    };
    if (paymentRequest) {
      dispatch({
        type: ActionTypes.setState,
        state: { paymentRequest: paymentRequest },
      });
    }
    history.push('/pay/method', params);
  };

  return <ProvidersContent isLoading={isLoading} onClick={onClick} />;
});

export default ProvidersScreen;
