import { doc, getDoc } from 'firebase/firestore';

import { db } from './FirebaseService';

export const getUserAccountInfo = (id: string) =>
  getDoc(doc(db, 'NabyAccount', `${id}`, 'publicData'))
    .then((doc) => {
      if (doc.exists()) {
        return { result: true, data: doc.data() };
      } else {
        throw new Error('No user found');
      }
    })
    .catch((err) => ({
      result: false,
      err,
    }));

export const getOrganizationInfo = (id: string) =>
  getDoc(doc(db, 'NabyOrganization', `${id}`))
    .then((doc) => {
      if (doc.exists()) {
        return { result: true, data: { id, ...doc.data() } };
      } else {
        throw new Error('No user found');
      }
    })
    .catch((err) => ({
      result: false,
      data: err,
    }));
