import { memo, useEffect } from 'react';
import { API } from '../../api/ApiClient';
import { useHistory } from 'react-router-dom';

import { NabyOrganization } from '../../models/NabyAccount';
import PaymentResultFailure from '../payment-result/PaymentResultFailure';
import ApplePayment from './ApplePayment';
import { usePaymentMethods } from '../../hooks';
import { stringToCents } from '../../utils/Money';
import { rcString } from '../../utils/RemoteConfig';
import Body from '../../widgets/layout/Body';
import Container from '../../widgets/layout/Container';
import Button from '../common/Button';
import Header from '../common/Header';
import translations from '../../assets/translations';

type Props = {
  isLoading: boolean;
  paymentFields: {
    userId: string;
    organisation: NabyOrganization;
    transaction: any;
  };
  bankTransferClick: () => void;
  cardClick: () => void;
  paymentRequestParam: any;
};

const PaymentMethodContent = memo(
  ({
    isLoading,
    paymentFields,
    bankTransferClick,
    cardClick,
    paymentRequestParam,
  }: Props) => {
    const history = useHistory();
    const { userId, organisation, transaction } = paymentFields;

    const maxAmount = Number.parseInt(rcString('stripeObMaxAmount')) || 1000000;
    const { stripeBankEnabled, stripeCardEnabled } = usePaymentMethods(
      organisation,
      stringToCents(transaction.amount) > maxAmount,
    );

    useEffect(() => {
      if (!stripeCardEnabled && !stripeBankEnabled) {
        API.sendNoPaymentMethodsMessage(userId);
      }
    }, [stripeCardEnabled, stripeBankEnabled]);

    return !stripeBankEnabled && !stripeCardEnabled ? (
      <PaymentResultFailure
        title={translations['error.title']}
        text={
          'It looks like Organic Market does not have any payment methods setup.\n\nThey need to fix this problem before you can make a payment.'
        }
        btnText={'Let them know'}
        onClick={() => {}}
      />
    ) : (
      <Container>
        <Body>
          <Header>{translations['method.title']}</Header>
          <>
            {stripeBankEnabled && (
              <>
                <Button
                  isLoading={isLoading}
                  btnType="primary"
                  label={translations.easyBankTransfer}
                  onClick={bankTransferClick}
                />
                <p
                  style={{
                    marginTop: 20,
                    textAlign: 'center',
                    fontSize: '12px',
                    lineHeight: '22px',
                    color: '#575757',
                  }}>
                  {translations.formatString(
                    translations['method.description'],
                    <b>{organisation.orgname}</b>,
                  )}
                </p>
                <Button
                  btnType="text"
                  label={translations.moreInfo}
                  onClick={() => history.push('/pay/more-info')}
                />
              </>
            )}

            {stripeCardEnabled && stripeBankEnabled && (
              <Header noBack>{translations['method.orPay']}</Header>
            )}

            {stripeCardEnabled && (
              <>
                <Button
                  disabled={isLoading}
                  btnType="outline"
                  label={translations.card}
                  onClick={cardClick}
                />
                <ApplePayment
                  disabled={isLoading}
                  paymentFields={paymentFields}
                  paymentRequestParam={paymentRequestParam}
                />
              </>
            )}
          </>
        </Body>
      </Container>
    );
  },
);

export default PaymentMethodContent;
