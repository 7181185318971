import { createContext, useReducer, Dispatch, ReactNode } from 'react';
import { PaymentRequest } from '@stripe/stripe-js';

import { NabyOrganization } from '../../models/NabyAccount';

interface QuickstartState {
  userId?: string;
  organisation?: NabyOrganization;
  transaction?: {
    amount: string;
    refNum: string;
    date: number;
  };
  paymentRequest?: PaymentRequest;
}

const initialState: QuickstartState = {};

export enum ActionTypes {
  setState = 'SET_STATE',
}

type QuickstartAction = {
  type: ActionTypes.setState;
  state: Partial<QuickstartState>;
};

interface QuickstartContext extends QuickstartState {
  dispatch: Dispatch<QuickstartAction>;
}

const Context = createContext<QuickstartContext>(
  initialState as QuickstartContext,
);

const { Provider } = Context;
export const AppProvider: React.FC<{ children: ReactNode }> = (props) => {
  const reducer = (
    state: QuickstartState,
    action: QuickstartAction,
  ): QuickstartState => {
    switch (action.type) {
      case ActionTypes.setState:
        return { ...state, ...action.state };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ ...state, dispatch }}>{props.children}</Provider>;
};

export default Context;
