import { httpsCallable } from 'firebase/functions';
import { PaymentIntent } from '@stripe/stripe-js';

import { RequiredPaymentIntentFields } from '../services/stripe/models';
import { functions } from '../services/FirebaseService';

export default class StripeApi {
  static createPaymentIntent = (
    paymentIntentFields: RequiredPaymentIntentFields,
  ) =>
    httpsCallable<any, { id: string; clientSecret: string }>(
      functions,
      'createStripePaymentIntent',
    )({
      userId: paymentIntentFields.userId,
      organisationId: paymentIntentFields.organisationId,
      amount: paymentIntentFields.amount,
      currency: paymentIntentFields.currency,
      refNum: paymentIntentFields.refNum,
      isOpenBanking: paymentIntentFields.isOpenBanking,
    });

  static processPaymentIntent = (
    paymentIntentId: string,
    paymentMethodId: string,
    country: string,
    amountCents: number,
  ) =>
    httpsCallable<any, PaymentIntent>(
      functions,
      'processPaymentIntent',
    )({ paymentIntentId, paymentMethodId, country, amountCents });

  static proccessPayment = (paymentFields: {
    userId: string;
    organisationId: string;
    amount: number;
    currency: string;
    refNum: string;
    date: number;
    country: string;
    paymentMethodId: string;
  }) =>
    httpsCallable<any, PaymentIntent>(
      functions,
      'stripeProcessPayment',
    )(paymentFields);
}
