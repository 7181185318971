import { isDesktop } from 'react-device-detect';
import '../../App.scss';

const Container = ({ children, className, innerId, innerRef }: any) => (
  <div
    className={`flex-root ${className}`}
    style={isDesktop ? { backgroundColor: '#DADADA' } : {}}>
    <div
      ref={innerRef}
      id={innerId}
      className={isDesktop ? 'inner' : 'mobile'}
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}>
      {children}
    </div>
  </div>
);

export default Container;
