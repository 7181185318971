import { memo } from 'react';

import './ProvidersContent.scss';
import Body from '../../widgets/layout/Body';
import Bottom from '../../widgets/layout/Bottom';
import Container from '../../widgets/layout/Container';
import Button from '../common/Button';
import Header from '../common/Header';
import translations from '../../assets/translations';
import { getImageUrl } from '../../utils/RemoteConfig';
import { openInNewTab } from '../../utils/utils';

type Props = { isLoading: boolean; onClick: () => void };

const ProvidersContent = memo(({ isLoading, onClick }: Props) => (
  <Container>
    <Body>
      <Header>{translations['providers.header']}</Header>
      <p id="providers-description">{translations['providers.description']}</p>
      <img
        id="providers-logos"
        src={getImageUrl('stripe_logo_black')}
        onClick={() => openInNewTab('https://stripe.com/')}
        style={{ height: 34, width: 150 }}
      />
    </Body>
    <Bottom style={{ justifyContent: 'center' }}>
      <Button
        label={translations.next}
        isLoading={isLoading}
        onClick={onClick}
      />
    </Bottom>
  </Container>
));

export default ProvidersContent;
