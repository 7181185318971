import qs from 'querystring';

import { apiClient } from './ApiClient';

export default class PlaidApi {
  static createPaymentLinkToken = async (orgId: string, piId: string) => {
    console.log({ orgId, piId });
    const response = await apiClient.post(
      '/plaidCreatePaymentLinkToken',
      qs.stringify({ orgId, piId }),
    );
    console.log(response.data);
    return response.data;
  };

  static createPaymentInitiation = async (
    organisationId: string,
    userId: string,
    ref: string,
    amount: number,
  ) => {
    const response = await apiClient.post(
      '/plaidCreatePaymentInitiation',
      qs.stringify({ organisationId, userId, ref, amount }),
    );
    console.log(response.data);
    return response.data;
  };

  static preinitTransaction = async (
    organisationId: string,
    userId: string,
    ref: string,
    amount: string,
    piId: string,
    counter: string,
  ) => {
    const response = await apiClient.post(
      '/plaidPreinitTransaction',
      qs.stringify({ organisationId, userId, ref, amount, piId, counter }),
    );
    console.log(response.data);
    return response.data;
  };
}
