import { useMemo } from 'react';

import { PaymentMethod } from '../models/NabyAccount';
import { rcString } from '../utils/RemoteConfig';

const usePaymentMethods = (organisation: any, amountRestriction?: boolean) => {
  const paymentMethods: PaymentMethod[] = useMemo(() => {
    return JSON.parse(rcString('payment_methods'))?.methods || [];
  }, []);

  const { stripeBankEnabled, stripeCardEnabled } = useMemo(() => {
    console.log(organisation.paymentMethodsPublic);
    console.log(paymentMethods);
    const stripeCardEnabled =
      !!organisation.paymentMethodsPublic?.['stripe.card'] &&
      !!paymentMethods.find((item) => item.type === 'stripe.card');
    const stripeBankEnabled =
      !!organisation.paymentMethodsPublic?.['stripe.bank'] &&
      !!paymentMethods.find((item) => item.type === 'stripe.bank') &&
      !amountRestriction;

    return { stripeCardEnabled, stripeBankEnabled };
  }, [organisation, amountRestriction]);

  return { stripeCardEnabled, stripeBankEnabled };
};

export { usePaymentMethods };
