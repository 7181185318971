export const currentEnv = 'DEV';
// export const currentEnv = 'STAGE';
// export const currentEnv = 'PROD';

const DEV_VARS = {
  STRIPE_PK: 'pk_test_T1sexQraRR6Io4ZEvHOJZNxl00MkeBn3s2',
  api: 'https://europe-west2-naby-core.cloudfunctions.net/',
  local_api: 'http://192.168.31.148:5000/naby-core/europe-west2',
  PLAID_ENV: 'sandbox',
  RECAPTCHA: '6LcheqgdAAAAAAB9JRd9xqnM3GoSB1574uGWEkzW',
};
const STAGE_VARS = {
  STRIPE_PK: 'pk_test_T1sexQraRR6Io4ZEvHOJZNxl00MkeBn3s2',
  api: 'https://europe-west2-stage-naby-core-934ce.cloudfunctions.net/',
  local_api: 'http://192.168.31.148:5000/stage-naby-core-934ce/europe-west2',
  PLAID_ENV: 'development',
  RECAPTCHA: '6LczaKsdAAAAADoOLSd56G4_oYFs1gSGvsvoJkWH',
};
const PROD_VARS = {
  STRIPE_PK: 'pk_live_BfxSbEMJRyculQMX6I6tQ3uC00W1blWLzc',
  api: 'https://europe-west2-prod-naby-core.cloudfunctions.net/',
  local_api: 'http://localhost:5001/prod-naby-core/europe-west2',
  PLAID_ENV: 'production',
  RECAPTCHA: '6LfCW7YdAAAAACuepiSSN9KO4vQXm-JQGh7II8ZE',
};

export const GetEnvVars = (env: string) => {
  //@ts-ignore
  if (env === 'STAGE') {
    return STAGE_VARS;
    //@ts-ignore
  } else if (env === 'PROD') {
    return PROD_VARS;
  }
  return DEV_VARS;
};

const EnvVars = GetEnvVars(currentEnv);

export default EnvVars;
