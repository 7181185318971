import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { logEvent } from 'firebase/analytics';

import '../../App.scss';
import { CheckoutForm } from '../../components/details/CheckoutForm';
import { analytics } from '../../services/FirebaseService';

interface Props {
  match: any;
  location: any;
}

const EnterPaymentDetailsScreen: FC<Props> = ({ match, location }) => {
  const transaction = location.state.transaction;
  const organisation = location.state.organisation;

  const history = useHistory();

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Pay - enter card',
      firebase_screen_class: 'Pay - enter card',
    });
  }, []);

  const showSuccess = () =>
    history.push('/pay/success', { organisation, transaction });
  const showFailure = (error: string) =>
    history.push('/pay/failure', { organisation, transaction, error });

  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          location={location}
          stripe={stripe}
          elements={elements}
          paymentSuccess={showSuccess}
          paymentFailed={showFailure}
        />
      )}
    </ElementsConsumer>
  );
};

const showFailure = () => {
  console.log('Payment Failed');
};

export default EnterPaymentDetailsScreen;
