import { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';
import './Header.scss';
import back from '../../../assets/images/back.svg';

type Props = { noBack?: boolean };

const Header: FC<Props> = memo(({ noBack, children }) => {
  const history = useHistory();
  return (
    <div>
      {!noBack && <img src={back} onClick={history.goBack} />}
      <h1>{children}</h1>
    </div>
  );
});

export default Header;
