import React from 'react';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { PaymentIntent, Stripe, StripeElements } from '@stripe/stripe-js';

import './CheckoutForm.scss';
import CardSection from './CardSection';
import Button from '../../components/common/Button';
import Container from '../../widgets/layout/Container';
import Body from '../../widgets/layout/Body';
import Header from '../common/Header';
import translations from '../../assets/translations';
import DataField from '../common/DataField';
import StripeApi from '../../api/StripeApi';
import { stringToCents } from '../../utils/Money';

interface Props {
  location: any;
  stripe: Stripe | null;
  elements: StripeElements | null;
  paymentSuccess: () => any;
  paymentFailed: (error: string) => any;
}

export class CheckoutForm extends React.Component<Props> {
  state = { name: '', loading: false, error: undefined };

  handleSubmit = async (event: any) => {
    this.setState({ loading: true });
    event.preventDefault();

    const { location, stripe, elements } = this.props;
    const userId = location.state.userId;
    const organisation = location.state.organisation;
    const transaction = location.state.transaction;
    const amount = stringToCents(transaction.amount);

    console.log({ stripe, elements });
    if (!stripe || !elements || this.state.name.length === 0) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      this.setState({ loading: false });
      return;
    }

    try {
      const card = elements.getElement(CardNumberElement);
      if (!card) {
        throw new Error('Something went wrong');
      }

      const paymentMethodRes = await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: this.state.name,
        },
      });
      const paymentMethod = paymentMethodRes.paymentMethod;

      if (paymentMethodRes.error || !paymentMethod) {
        throw new Error(paymentMethodRes.error.message);
      }

      const paymentIntentRes = (
        await StripeApi.proccessPayment({
          userId,
          organisationId: organisation?.id || '',
          amount,
          currency: 'gbp',
          date: transaction.date,
          refNum: transaction.refNum,
          paymentMethodId: paymentMethod.id,
          country: paymentMethod.card?.country || 'undefined',
        })
      ).data;

      if (
        paymentIntentRes.status === 'requires_action' &&
        paymentIntentRes.client_secret
      ) {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          paymentIntentRes.client_secret,
        );
        if (error) {
          throw new Error(error.message);
        }
        if (paymentIntent?.status === 'succeeded') {
          this.props.paymentSuccess();
        } else {
          throw new Error('Something went wrong');
        }
      } else if (paymentIntentRes.status === 'succeeded') {
        this.props.paymentSuccess();
      } else {
        throw new Error('Something went wrong');
      }
    } catch (error: any) {
      this.props.paymentFailed(error?.toString() || '');
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Container>
        <Body>
          <Header>{translations['details.cardDetails']}</Header>
          <form className="card-form" onSubmit={this.handleSubmit}>
            <DataField
              autoCapitalize="words"
              isInput
              error={this.state.error}
              title={translations.nameCard}
              value={this.state.name}
              //@ts-ignore
              handleChange={(event: any) => {
                this.setState({
                  name: event.target.value,
                  error:
                    event.target.value.length === 0
                      ? 'Name in required'
                      : undefined,
                });
              }}
            />

            <CardSection />
            <Button
              type="submit"
              isLoading={this.state.loading}
              label={translations.payNow}
            />
          </form>
        </Body>
      </Container>
    );
  }
}
